import React from "react";
import { useParams } from "react-router-dom";
import { useRoutes } from "utils/routes";

import PageSecondaryNavigation, {
  PageNavLink,
} from "components/PageSecondaryNavigation";
import ExplainWorkbookList from "components/ExplainWorkbookList";
import ServerExplainWorkbookList from "components/ServerExplainWorkbookList";
import PageContent from "components/PageContent";
import { useFeature } from "components/OrganizationFeatures";
import UpgradeRequired from "components/UpgradeRequired";
import Callout from "components/Callout";

type QueryTuningTabType = "overview" | "workbook";

const QueryTuning: React.FunctionComponent<{ tab: QueryTuningTabType }> = ({
  tab,
}) => {
  const { databaseId, serverId } = useParams();
  const { databaseWorkbooks, serverWorkbooks } = useRoutes();
  const hasQueryTuning = useFeature("queryTuning");

  const featureNav = databaseId ? (
    <PageSecondaryNavigation>
      <PageNavLink to={databaseWorkbooks(databaseId)}>Workbooks</PageNavLink>
    </PageSecondaryNavigation>
  ) : (
    <PageSecondaryNavigation>
      <PageNavLink to={serverWorkbooks(serverId)}>Workbooks</PageNavLink>
    </PageSecondaryNavigation>
  );

  return hasQueryTuning ? (
    <PageContent
      title="Query Tuning"
      pageCategory="query-tuning"
      pageName="index"
      featureNav={featureNav}
    >
      <Callout className="mb-4">
        Query Tuning is now available in beta, helping you optimize slow queries
        by testing different parameters, query variants and comparing their
        EXPLAIN plans.{" "}
        <a href="https://pganalyze.com/docs/query-tuning">Learn more.</a>
      </Callout>
      <QueryTuningContent tab={tab} databaseId={databaseId} />
    </PageContent>
  ) : (
    <UpgradeRequired feature="Query Tuning" />
  );
};

const QueryTuningContent: React.FunctionComponent<{
  tab: QueryTuningTabType;
  databaseId: string;
}> = ({ tab, databaseId }) => {
  if (tab !== "workbook") {
    return null;
  }
  return databaseId ? <ExplainWorkbookList /> : <ServerExplainWorkbookList />;
};

export default QueryTuning;
