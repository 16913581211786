import React from "react";

import { Link } from "react-router-dom";

import { useRoutes } from "utils/routes";

import Popover from "components/Popover";
import { InfoIcon, SettingsIcon } from "components/Icons";
import TextTag from "components/TextTag";

import styles from "./style.module.scss";

type Props = {
  href?: string;
  title: string;
  content: string;
};

export const DocsSnippet: React.FunctionComponent<Props> = ({
  href,
  title,
  content,
}) => {
  if (!href) {
    return (
      <Popover title={title} content={content}>
        <InfoIcon />
      </Popover>
    );
  }

  return (
    <Popover title={title} content={content}>
      <a href={href} className={styles.link}>
        <InfoIcon />
      </a>
    </Popover>
  );
};

export default DocsSnippet;

export const ConfigSettingDocsSnippet: React.FunctionComponent<{
  serverId?: string;
  configName: string;
  noIcon?: boolean;
}> = ({ serverId, configName, noIcon }) => {
  const { serverConfigSetting } = useRoutes();

  if (!serverId) {
    return (
      <Popover
        title={configName}
        content={ConfigSettingDescription[configName]}
      >
        {!noIcon && (
          <TextTag>
            <SettingsIcon className="mr-1" />
          </TextTag>
        )}
        {configName}
      </Popover>
    );
  }

  return (
    <Link to={serverConfigSetting(serverId, configName)}>
      <Popover
        title={configName}
        content={ConfigSettingDescription[configName]}
      >
        {!noIcon && (
          <TextTag>
            <SettingsIcon className="mr-1" />
          </TextTag>
        )}
        {configName}
      </Popover>
    </Link>
  );
};

export const ViewPermission: React.FunctionComponent = () => {
  return (
    <DocsSnippet
      href="/docs/permissions"
      title="View Permission"
      content="Allows the member to see statistics information for a database, including full query details as well as complete log information when using Log Insights."
    />
  );
};

export const ModifyPermission: React.FunctionComponent = () => {
  return (
    <DocsSnippet
      href="/docs/permissions"
      title="Modify Permission"
      content="Allows the member to acknowledge check-ups and change configuration settings in pganalyze for databases and servers."
    />
  );
};

export const BillingPermission: React.FunctionComponent = () => {
  return (
    <DocsSnippet
      href="/docs/permissions"
      title="Billing Permission"
      content="Allows the member to input billing details, change the subscription an organization is on, as well as view invoices."
    />
  );
};

export const APIPermission: React.FunctionComponent = () => {
  return (
    <DocsSnippet
      href="/docs/permissions"
      title="API Permission"
      content="Allows the member to see API keys, typically required for integrating the pganalyze collector on a server."
    />
  );
};

export const ManagePermission: React.FunctionComponent = () => {
  return (
    <DocsSnippet
      href="/docs/permissions"
      title="Manage Permission"
      content="Allows the member to manage other members, assign and change roles, and create and remove servers."
    />
  );
};

export const TuneQueriesPermission: React.FunctionComponent = () => {
  return (
    <DocsSnippet
      href="/docs/permissions"
      title="Tune Queries Permission"
      content="Allows the member to create a workbook to tune queries. This also allows the member to run EXPLAIN ANALYZE queries using the collector, to collect EXPLAIN plans for tuning queries."
    />
  );
};

export const ConfigSettingDescription: { [key: string]: string } = {
  autovacuum:
    "Controls whether the server should run the autovacuum launcher daemon on this table. Note that even when this parameter is disabled, the system will launch autovacuum processes if necessary to prevent transaction ID wraparound.",
  autovacuum_freeze_max_age:
    "Specifies the maximum age (in transactions) that a table's pg_class.relfrozenxid field can attain before a VACUUM operation is forced to prevent transaction ID wraparound within the table.",
  autovacuum_multixact_freeze_max_age:
    "Specifies the maximum age (in multixacts) that a table's pg_class.relminmxid field can attain before a VACUUM operation is forced to prevent multixact ID wraparound within the table.",
  vacuum_failsafe_age:
    "Specifies the maximum age (in transactions) that a table's pg_class.relfrozenxid field can attain before VACUUM takes extraordinary measures to avoid system-wide transaction ID wraparound failure. This is VACUUM's strategy of last resort.",
  vacuum_multixact_failsafe_age:
    "Specifies the maximum age (in multixacts) that a table's pg_class.relminmxid field can attain before VACUUM takes extraordinary measures to avoid system-wide multixact ID wraparound failure. This is VACUUM's strategy of last resort.",
  autovacuum_vacuum_cost_delay:
    "The length of time, in milliseconds, that the process will sleep when the cost limit has been exceeded.",
  autovacuum_vacuum_cost_limit:
    "The accumulated cost that will cause the vacuuming process to sleep. Note that the value is distributed proportionally among the running autovacuum workers, if there is more than one, so that the sum of the limits for each worker does not exceed the value of this variable.",
  autovacuum_vacuum_threshold:
    "Specifies the minimum number of updated or deleted tuples needed to trigger a VACUUM in any one table.",
  autovacuum_vacuum_scale_factor:
    "Specifies a fraction of the table size to add to autovacuum_vacuum_threshold when deciding whether to trigger a VACUUM.",
  autovacuum_analyze_threshold:
    "Specifies the minimum number of inserted, updated or deleted tuples needed to trigger an ANALYZE in any one table.",
  autovacuum_analyze_scale_factor:
    "Specifies a fraction of the table size to add to autovacuum_analyze_threshold when deciding whether to trigger an ANALYZE.",
  autovacuum_max_workers:
    "Specifies the maximum number of autovacuum processes (other than the autovacuum launcher) that may be running at any one time.",
  autovacuum_naptime:
    "Specifies the minimum delay between autovacuum runs on any given database.",
  autovacuum_vacuum_insert_threshold:
    "Specifies the number of inserted tuples needed to trigger a VACUUM in any one table.",
  autovacuum_vacuum_insert_scale_factor:
    "Specifies a fraction of the table size to add to autovacuum_vacuum_insert_threshold when deciding whether to trigger a VACUUM.",
};

export const AutovacuumEnabled: React.FunctionComponent<{
  serverId: string;
}> = ({ serverId }) => {
  const { serverConfigSetting } = useRoutes();
  return (
    <DocsSnippet
      href={serverConfigSetting(serverId, "autovacuum")}
      title="autovacuum"
      content={ConfigSettingDescription["autovacuum"]}
    />
  );
};

export const AutovacuumFreezeMaxAge: React.FunctionComponent<{
  serverId: string;
}> = ({ serverId }) => {
  const { serverConfigSetting } = useRoutes();
  return (
    <DocsSnippet
      href={serverConfigSetting(serverId, "autovacuum_freeze_max_age")}
      title="autovacuum_freeze_max_age / autovacuum_multixact_freeze_max_age"
      content={`1) ${ConfigSettingDescription["autovacuum_freeze_max_age"]} 2) ${ConfigSettingDescription["autovacuum_multixact_freeze_max_age"]}`}
    />
  );
};

export const AutovacuumFreezeMaxAgeOnly: React.FunctionComponent<{
  serverId: string;
}> = ({ serverId }) => {
  const { serverConfigSetting } = useRoutes();
  return (
    <DocsSnippet
      href={serverConfigSetting(serverId, "autovacuum_freeze_max_age")}
      title="autovacuum_freeze_max_age"
      content={ConfigSettingDescription["autovacuum_freeze_max_age"]}
    />
  );
};

export const AutovacuumMultixactFreezeMaxAge: React.FunctionComponent<{
  serverId: string;
}> = ({ serverId }) => {
  const { serverConfigSetting } = useRoutes();
  return (
    <DocsSnippet
      href={serverConfigSetting(
        serverId,
        "autovacuum_multixact_freeze_max_age",
      )}
      title="autovacuum_multixact_freeze_max_age"
      content={ConfigSettingDescription["autovacuum_multixact_freeze_max_age"]}
    />
  );
};

export const VacuumFailsafeAge: React.FunctionComponent<{
  serverId: string;
}> = ({ serverId }) => {
  const { serverConfigSetting } = useRoutes();
  return (
    <DocsSnippet
      href={serverConfigSetting(serverId, "vacuum_failsafe_age")}
      title="vacuum_failsafe_age"
      content={ConfigSettingDescription["vacuum_failsafe_age"]}
    />
  );
};

export const VacuumMultixactFailsafeAge: React.FunctionComponent<{
  serverId: string;
}> = ({ serverId }) => {
  const { serverConfigSetting } = useRoutes();
  return (
    <DocsSnippet
      href={serverConfigSetting(serverId, "vacuum_multixact_failsafe_age")}
      title="vacuum_multixact_failsafe_age"
      content={ConfigSettingDescription["vacuum_multixact_failsafe_age"]}
    />
  );
};

export const AutovacuumVacuumCostDelay: React.FunctionComponent<{
  serverId: string;
}> = ({ serverId }) => {
  const { serverConfigSetting } = useRoutes();
  return (
    <DocsSnippet
      href={serverConfigSetting(serverId, "autovacuum_vacuum_cost_delay")}
      title="autovacuum_vacuum_cost_delay"
      content={ConfigSettingDescription["autovacuum_vacuum_cost_delay"]}
    />
  );
};

export const AutovacuumVacuumCostLimit: React.FunctionComponent<{
  serverId: string;
}> = ({ serverId }) => {
  const { serverConfigSetting } = useRoutes();
  return (
    <DocsSnippet
      href={serverConfigSetting(serverId, "autovacuum_vacuum_cost_limit")}
      title="autovacuum_vacuum_cost_limit"
      content={ConfigSettingDescription["autovacuum_vacuum_cost_limit"]}
    />
  );
};

export const AutovacuumVacuumThreshold: React.FunctionComponent<{
  serverId: string;
}> = ({ serverId }) => {
  const { serverConfigSetting } = useRoutes();
  return (
    <DocsSnippet
      href={serverConfigSetting(serverId, "autovacuum_vacuum_threshold")}
      title="autovacuum_vacuum_threshold"
      content={ConfigSettingDescription["autovacuum_vacuum_threshold"]}
    />
  );
};

export const AutovacuumVacuumScaleFactor: React.FunctionComponent<{
  serverId: string;
}> = ({ serverId }) => {
  const { serverConfigSetting } = useRoutes();
  return (
    <DocsSnippet
      href={serverConfigSetting(serverId, "autovacuum_vacuum_scale_factor")}
      title="autovacuum_vacuum_scale_factor"
      content={ConfigSettingDescription["autovacuum_vacuum_scale_factor"]}
    />
  );
};

export const AutovacuumAnalyzeThreshold: React.FunctionComponent<{
  serverId: string;
}> = ({ serverId }) => {
  const { serverConfigSetting } = useRoutes();
  return (
    <DocsSnippet
      href={serverConfigSetting(serverId, "autovacuum_analyze_threshold")}
      title="autovacuum_analyze_threshold"
      content={ConfigSettingDescription["autovacuum_analyze_threshold"]}
    />
  );
};

export const AutovacuumAnalyzeScaleFactor: React.FunctionComponent<{
  serverId: string;
}> = ({ serverId }) => {
  const { serverConfigSetting } = useRoutes();
  return (
    <DocsSnippet
      href={serverConfigSetting(serverId, "autovacuum_analyze_scale_factor")}
      title="autovacuum_analyze_scale_factor"
      content={ConfigSettingDescription["autovacuum_analyze_scale_factor"]}
    />
  );
};

export const HiddenDatabases: React.FunctionComponent = () => {
  return (
    <DocsSnippet
      href="https://pganalyze.zendesk.com/hc/en-us/articles/360007543018-Can-I-monitor-multiple-databases-on-the-same-server-"
      title="Hidden databases"
      content="Hidden databases are seen by the system but not monitored. You can collect statistics on hidden databases by changing the db_name setting in your collector configuration."
    />
  );
};
