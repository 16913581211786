import React from "react";

import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";

import { useFlashes, Flash as FlashType } from "components/WithFlashes";
import Button from "components/Button";
import { useDismissable } from "utils/hooks";

import styles from "./style.module.scss";

const PageFlash: React.FunctionComponent = () => {
  const flashes = useFlashes();

  if (flashes.length === 0) {
    return null;
  }

  return (
    <div>
      {flashes.map((flash: FlashType, idx: number) => (
        <Flash key={idx} flash={flash} />
      ))}
    </div>
  );
};

const Flash: React.FunctionComponent<{ flash: FlashType }> = ({ flash }) => {
  const [isDismissed, handleDismiss] = useDismissable(
    flash.dismissable ? flash.id : undefined,
  );
  if (isDismissed) {
    return null;
  }

  return (
    <div className={classNames(styles[flash.level])}>
      {flash.dismissable && (
        <Button
          bare
          className="float-right -mr-1 -mt-2 ml-1"
          onClick={handleDismiss}
        >
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      )}
      <strong>{flash.level}!</strong>
      {"escapedHtmlMsg" in flash ? (
        // eslint-disable-next-line react/no-danger
        <span dangerouslySetInnerHTML={{ __html: flash.escapedHtmlMsg }} />
      ) : (
        flash.msg
      )}
    </div>
  );
};

export default PageFlash;
