import React from "react";

import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleXmark,
  faExclamationTriangle,
  faLightbulbOn,
  faTimes,
  IconDefinition,
} from "@fortawesome/pro-solid-svg-icons";
import Button from "components/Button";
import { useDismissable } from "utils/hooks";

type CalloutVariantType = "info" | "warning" | "error";
type CalloutButton = {
  label: string;
  onClick: () => void;
};

type DismissableProps =
  | {
      dismissable: true;
      id: string;
    }
  | {
      dismissable?: false;
      id?: undefined;
    };

const Callout: React.FunctionComponent<
  {
    title?: string;
    children: React.ReactNode;
    variant?: CalloutVariantType;
    className?: string;
    learnMoreLink?: string;
    calloutButton?: CalloutButton;
    thin?: boolean;
  } & DismissableProps
> = ({
  title,
  children,
  className,
  learnMoreLink,
  variant = "info",
  calloutButton,
  thin,
  id,
  dismissable,
}) => {
  const [isDismissed, handleDismiss] = useDismissable(
    dismissable ? id : undefined,
  );
  if (isDismissed) {
    return null;
  }
  const commonStyles = thin
    ? "font-medium border rounded px-3 py-1"
    : "font-medium border rounded px-4 py-3";
  let variantStyles: string;
  let iconStyles: string;
  let icon: IconDefinition;
  switch (variant) {
    case "info":
      variantStyles = "bg-slate-50 border-slate-200 text-slate-700";
      iconStyles = "text-[#337ab7]";
      icon = faLightbulbOn;
      break;
    case "warning":
      variantStyles = "bg-yellow-50 border-yellow-500 text-yellow-700";
      iconStyles = "text-yellow-600";
      icon = faExclamationTriangle;
      break;
    case "error":
      variantStyles = "border-red-600 text-red-600";
      iconStyles = "text-red-600";
      icon = faCircleXmark;
      break;
  }
  const content = (
    <>
      {title && <div className="font-semibold mb-1">{title}</div>}
      {children}
    </>
  );
  return (
    <div className={classNames(commonStyles, variantStyles, className)}>
      {dismissable && (
        <Button
          bare
          className="float-right -mr-1 -mt-1 ml-1"
          onClick={handleDismiss}
        >
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      )}
      <div className="grid grid-cols-[28px_1fr]">
        <div className="py-1">
          <FontAwesomeIcon className={iconStyles} icon={icon} />
        </div>
        <div className="py-1">
          {calloutButton ? (
            <div className="grid grid-cols-[1fr_180px] items-center gap-3">
              <div>{content}</div>
              <button
                className="btn btn-success"
                onClick={calloutButton.onClick}
              >
                {calloutButton.label}
              </button>
            </div>
          ) : (
            <>{content}</>
          )}
          {learnMoreLink && (
            <div className="mt-2">
              <a target="_blank" rel="noopener" href={learnMoreLink}>
                Learn more in documentation
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Callout;
