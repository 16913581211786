export const validatePlannerSettingValue = (
  value: string,
  setting: PlannerSettingType,
): { valid: boolean; error: string } => {
  switch (setting.vartype) {
    case "bool":
    case "enum":
      const values =
        setting.vartype === "enum" ? setting.enumvals : ["on", "off"];
      if (!values.includes(value)) {
        return {
          valid: false,
          error: `The value must be ${values.join(" or ")}.`,
        };
      }
      break;
    case "real":
    case "integer":
      const num = Number(value);
      if (isNaN(num)) {
        return { valid: false, error: "The value is an invalid number." };
      } else if (num < setting.minVal || setting.maxVal < num) {
        return {
          valid: false,
          error: `${value} is outside the valid range for parameter (${setting.minVal} .. ${setting.maxVal}).`,
        };
      }
      break;
  }
  // Do not validate for string case, or the case setting info is not available (vartype is null)
  return { valid: true, error: "" };
};

export type PlannerSettingVartypeType =
  | "bool"
  | "enum"
  | "integer"
  | "real"
  | "string"
  | null;

export type PlannerSettingType = {
  value: string;
  docSnippetHtml: string;
  unit: string | null;
  shortDesc: string | null;
  vartype: PlannerSettingVartypeType;
  minVal: number | null;
  maxVal: number | null;
  enumvals: string[] | null;
};

export const PlannerSettingNames = [
  // Planner Method Configuration
  "enable_async_append",
  "enable_bitmapscan",
  "enable_gathermerge",
  "enable_group_by_reordering",
  "enable_hashagg",
  "enable_hashjoin",
  "enable_incremental_sort",
  "enable_indexscan",
  "enable_indexonlyscan",
  "enable_material",
  "enable_memoize",
  "enable_mergejoin",
  "enable_nestloop",
  "enable_parallel_append",
  "enable_parallel_hash",
  "enable_partition_pruning",
  "enable_partitionwise_join",
  "enable_partitionwise_aggregate",
  "enable_presorted_aggregate",
  "enable_seqscan",
  "enable_sort",
  "enable_tidscan",
  // Planner Cost Constants
  "seq_page_cost",
  "random_page_cost",
  "cpu_tuple_cost",
  "cpu_index_tuple_cost",
  "cpu_operator_cost",
  "parallel_setup_cost",
  "parallel_tuple_cost",
  "min_parallel_table_scan_size",
  "min_parallel_index_scan_size",
  "effective_cache_size",
  "jit_above_cost",
  "jit_inline_above_cost",
  "jit_optimize_above_cost",
  // Genetic Query Optimizer
  "geqo",
  "geqo_threshold",
  "geqo_effort",
  "geqo_pool_size",
  "geqo_generations",
  "geqo_selection_bias",
  "geqo_seed",
  // Other Planner Options
  "default_statistics_target",
  "constraint_exclusion",
  "cursor_tuple_fraction",
  "from_collapse_limit",
  "jit",
  "join_collapse_limit",
  "plan_cache_mode",
  "recursive_worktable_factor",
  // Other Non Planner Options (custom ones)
  "hash_mem_multiplier",
  "work_mem",
  "search_path",
];
