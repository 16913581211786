import React, { useState, useRef, useLayoutEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/pro-regular-svg-icons";
import {
  faChevronDoubleDown,
  faChevronDoubleUp,
} from "@fortawesome/pro-solid-svg-icons";

import docs from "../../../../../../docs/directory.json";

import Badge from "components/Badge";

import { Annotation, Node } from "types/explain";

import styles from "./style.module.scss";
import { formatBytes } from "utils/format";

const Overview: React.FunctionComponent<{
  annotations: Annotation[];
  node: Node;
}> = ({ annotations, node }) => {
  return (
    <>
      <NodeDetailsDocumentation nodeType={node["Node Type"]} />
      <SignificantFields node={node} />
      <hr />
      {annotations.length > 0 && (
        <>
          <NodeDetailsInsights annotations={annotations} />
          <hr />
        </>
      )}
    </>
  );
};

const NodeDetailsDocumentation: React.FunctionComponent<{
  nodeType: string;
}> = ({ nodeType }) => {
  if (!(nodeType in docs.planNodes)) {
    return null;
  }
  const nodeDocs = docs.planNodes[nodeType];
  return (
    <div className="my-1">
      <div>
        {nodeDocs.shortDescription}{" "}
        <a href={`https://pganalyze.com` + nodeDocs.path}>Learn more</a>
      </div>
    </div>
  );
};

const NodeDetailsInsights: React.FunctionComponent<{
  annotations: Annotation[];
}> = ({ annotations }) => {
  return (
    <div className={styles.insights}>
      <h4 className="text-lg mb-0 text-[#606060]">
        Insights ({annotations.length})
      </h4>
      <ul className={styles.annotations}>
        {annotations.map((a, i) => {
          const insightInfo = docs.explainInsights[a.summary];
          const insightLink = insightInfo && insightInfo.path;
          return (
            <li key={i}>
              <Badge>{a.summary}</Badge> {a.detail}{" "}
              {insightLink && (
                <a
                  href={`https://pganalyze.com` + insightLink}
                  title="Learn more"
                >
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                </a>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const SignificantFields: React.FunctionComponent<{
  node: Node;
}> = ({ node }) => {
  const nodeType = node["Node Type"];
  if (!(nodeType in docs.planNodes)) {
    return null;
  }
  const nodeDocs = docs.planNodes[nodeType];
  const sigFields = nodeDocs.importantFields.filter((f: string) => f in node);
  if (sigFields.length === 0) {
    return null;
  }
  return (
    <div className={styles.significantFields}>
      <dl>
        {sigFields.map((f: string) => {
          return <SignificantField key={f} label={f} value={node[f]} />;
        })}
      </dl>
    </div>
  );
};

type IncrementalSortGroupInfo = {
  "Group Count": number;
  "Sort Methods Used": string[];
  "Sort Space Memory": {
    "Peak Sort Space Used": number;
    "Average Sort Space Used": number;
  };
  "Sort Space Disk": {
    "Peak Sort Space Used": number;
    "Average Sort Space Used": number;
  };
};

function isIncrementalSortGroupInfo(
  value: any,
): value is IncrementalSortGroupInfo {
  return (
    typeof value === "object" &&
    ["Group Count", "Sort Methods Used", "Sort Space Memory"].every(
      (k) => k in value,
    )
  );
}

type SignificantFieldValue =
  | boolean
  | string
  | number
  | string[]
  | number[]
  | IncrementalSortGroupInfo;

const SignificantField: React.FunctionComponent<{
  label: string;
  value: SignificantFieldValue;
}> = ({ label, value }) => {
  const [expanded, setExpanded] = useState(false);
  const [overflowing, setOverflowing] = useState(false);
  const ddRef = useRef(null);
  useLayoutEffect(() => {
    if (
      ddRef.current &&
      ddRef.current.offsetWidth < ddRef.current.scrollWidth
    ) {
      setOverflowing(true);
    }
  }, [ddRef]);

  const handleToggleExpanded = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setExpanded((exp) => !exp);
  };

  const formattedValue = formatSignificantField(value);

  return (
    <React.Fragment>
      <dt>
        {label}
        {overflowing && (
          <a
            title={expanded ? "show less" : "show more"}
            className={styles.significantFieldToggleExpanded}
            href=""
            onClick={handleToggleExpanded}
          >
            <FontAwesomeIcon
              icon={expanded ? faChevronDoubleUp : faChevronDoubleDown}
            />
          </a>
        )}
      </dt>
      <dd
        ref={ddRef}
        className={
          expanded
            ? styles.significantFieldExpanded
            : styles.significantFieldCollapsed
        }
        title={formattedValue}
      >
        {formattedValue}
      </dd>
    </React.Fragment>
  );
};

function formatSignificantField(value: SignificantFieldValue): string {
  if (typeof value === "boolean") {
    return String(value);
  }
  if (isIncrementalSortGroupInfo(value)) {
    const groupCount = value["Group Count"];
    const sortMethods = value["Sort Methods Used"];
    const avgMem =
      (value["Sort Space Memory"]["Average Sort Space Used"] ?? 0) * 1024;
    const peakMem =
      (value["Sort Space Memory"]["Peak Sort Space Used"] ?? 0) * 1024;

    const avgDisk =
      (value["Sort Space Disk"]?.["Average Sort Space Used"] ?? 0) * 1024;
    const peakDisk =
      (value["Sort Space Disk"]?.["Peak Sort Space Used"] ?? 0) * 1024;
    const diskStats = value["Sort Space Disk"]
      ? `, avg. disk: ${formatBytes(avgDisk)}, peak disk: ${formatBytes(
          peakDisk,
        )}`
      : "";
    return `${groupCount} groups, sort method(s): ${formatSignificantField(
      sortMethods,
    )}, avg. memory: ${formatBytes(avgMem)}, peak memory: ${formatBytes(
      peakMem,
    )}${diskStats}`;
  }
  if (Array.isArray(value)) {
    return value.map((item) => formatSignificantField(item)).join(", ");
  }
  return String(value);
}

export default Overview;
