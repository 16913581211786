import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";

import WORKBOOK_MUTATION from "./Mutation.workbook.graphql";
import VARIANT_MUTATION from "./Mutation.variant.graphql";
import WORKBOOK_DETAIL_QUERY from "../ExplainWorkbook/Query.graphql";
import WORKBOOK_LIST_QUERY from "../Query.graphql";
import {
  ExplainWorkbookDetails_getExplainWorkbookDetails as ExplainWorkbookType,
  ExplainWorkbookDetails_getExplainWorkbookDetails_explainQueries as ExplainQueryType,
} from "../ExplainWorkbook/types/ExplainWorkbookDetails";
import {
  DeleteExplainWorkbook,
  DeleteExplainWorkbookVariables,
} from "./types/DeleteExplainWorkbook";
import {
  DeleteExplainQuery,
  DeleteExplainQueryVariables,
} from "./types/DeleteExplainQuery";
import { useRoutes } from "utils/routes";
import Popover from "components/Popover";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFingerprint,
  faPlus,
  faUser,
} from "@fortawesome/pro-solid-svg-icons";
import { faEdit, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import QueryTags, { QueryTagType } from "components/QueryTags";
import Button from "components/Button";
import moment from "moment";
import { formatTimestampLong } from "utils/format";
import WorkbookNameEditPanel from "../WorkbookNameEditPanel";
import classNames from "classnames";
import Badge from "components/Badge";
import VariantNameEditPanel from "../VariantNameEditPanel";

export const ExplainWorkbookSidebar = ({
  workbook,
  permittedToTuneQueries,
}: {
  workbook: ExplainWorkbookType;
  permittedToTuneQueries: boolean;
}) => {
  const { databaseId } = useParams();
  const { databaseWorkbooks } = useRoutes();
  const [showEdit, setShowEdit] = useState(false);
  const navigate = useNavigate();
  const [deleteExplainWorkbook] = useMutation<
    DeleteExplainWorkbook,
    DeleteExplainWorkbookVariables
  >(WORKBOOK_MUTATION);

  const handleDismissEdit = () => {
    setShowEdit(false);
  };

  const handleEditWorkbook = () => {
    setShowEdit(true);
  };

  const handleDeleteWorkbook = () => {
    if (window.confirm(`Delete workbook "${workbook.name}"?`)) {
      deleteExplainWorkbook({
        variables: { workbookId: workbook.id },
        refetchQueries: [
          {
            query: WORKBOOK_LIST_QUERY,
            variables: {
              databaseId: workbook.databaseId,
            },
          },
        ],
        awaitRefetchQueries: true,
        onCompleted: () => {
          navigate(databaseWorkbooks(databaseId));
        },
      });
    }
  };
  return (
    <div className="w-[300px]">
      <SidebarNav
        workbook={workbook}
        permittedToTuneQueries={permittedToTuneQueries}
      />
      <hr />
      <div className="leading-7 mt-0 font-medium">Owner</div>
      <div className="mb-2">{workbook.user.fullname || "Deleted User"}</div>
      <div className="leading-7 mt-0 font-medium">Workbook description</div>
      <div className="mb-2">
        {workbook.description ?? (
          <span className="italic text-[#606060]">No description</span>
        )}
      </div>
      <div className="leading-7 mt-0 font-medium">Last edit</div>
      <div className="mb-2">
        {formatTimestampLong(moment.unix(workbook.lastActivityAt))}
      </div>
      <hr />
      <div className="flex flex-col gap-3 items-start mb-8">
        <Button bare onClick={handleEditWorkbook} className="!text-[#337ab7]">
          <FontAwesomeIcon icon={faEdit} /> Edit name and description
        </Button>
        <Button bare onClick={handleDeleteWorkbook} className="!text-[#d43f3a]">
          <FontAwesomeIcon icon={faTrashAlt} /> Delete workbook
        </Button>
      </div>
      {showEdit && (
        <WorkbookEditPanel workbook={workbook} onDismiss={handleDismissEdit} />
      )}
    </div>
  );
};

function WorkbookEditPanel({
  workbook,
  onDismiss,
}: {
  workbook: ExplainWorkbookType;
  onDismiss: () => void;
}) {
  const [name, setName] = useState(workbook.name);
  const [description, setDescription] = useState(workbook.description);

  return (
    <WorkbookNameEditPanel
      onDismiss={onDismiss}
      name={name}
      description={description}
      setName={setName}
      setDescription={setDescription}
      workbook={workbook}
    />
  );
}

const SidebarNav = ({
  workbook,
  permittedToTuneQueries,
}: {
  workbook: ExplainWorkbookType;
  permittedToTuneQueries: boolean;
}) => {
  const { databaseId } = useParams();
  const {
    databaseWorkbookVariants,
    databaseWorkbookVariant,
    databaseWorkbookVariantNew,
  } = useRoutes();
  const [deleteExplainQuery] = useMutation<
    DeleteExplainQuery,
    DeleteExplainQueryVariables
  >(VARIANT_MUTATION);

  const menuList: {
    to: string;
    variantId: string;
    title: string;
    end: boolean;
    draft?: boolean;
  }[] = [
    {
      to: databaseWorkbookVariants(databaseId, workbook.id),
      variantId: "0",
      title: "All Query Plans",
      end: true,
    },
    {
      to: databaseWorkbookVariant(
        databaseId,
        workbook.id,
        workbook.baselineQuery.id,
      ),
      variantId: workbook.baselineQuery.id,
      title: "Baseline",
      end: false,
    },
  ];
  workbook.explainQueries.forEach((explainQuery) => {
    menuList.push({
      to: databaseWorkbookVariant(databaseId, workbook.id, explainQuery.id),
      variantId: explainQuery.id,
      title: explainQuery.name,
      end: false,
      draft: explainQuery.draft,
    });
  });
  // sort menu list by variant ID
  const sortedMenuList = menuList.sort(
    (a, b) => Number(a.variantId) - Number(b.variantId),
  );

  return (
    <div className="font-medium flex flex-col gap-2">
      <div>
        <Popover
          wrapperClassName="w-full"
          content={
            !permittedToTuneQueries && (
              <div>
                You are not allowed to create new query variants - you do not
                have the <strong>Tune Queries</strong> permission on this
                database.
              </div>
            )
          }
        >
          <Link
            to={databaseWorkbookVariantNew(databaseId, workbook.id)}
            className={classNames(
              "btn btn-success w-full !px-4 !py-2.5 !font-medium",
              permittedToTuneQueries ? "" : "pointer-events-none opacity-[.65]",
            )}
          >
            <FontAwesomeIcon icon={faPlus} className="mr-1" /> New Query Variant
          </Link>
        </Popover>
      </div>
      {sortedMenuList.map((menu, idx) => {
        return (
          <NavLink
            to={menu.to}
            className={({ isActive }) =>
              isActive
                ? "py-2 px-2.5 bg-[#F9FAFB] rounded-md border border-[#E8E8EE] text-[#19152C] hover:text-[#19152C]"
                : "py-2 px-2.5 rounded-md border border-transparent text-[#606060] hover:text-[#19152C] hover:bg-[#F9FAFB]"
            }
            end={menu.end}
            key={`menu${idx}`}
          >
            <div className="flex">
              <div className="flex-grow">
                {menu.title}
                {menu.draft && <Badge className="ml-2">Draft</Badge>}
              </div>
              {menu.variantId !== "0" &&
                menu.variantId !== workbook.baselineQuery.id && (
                  <Button
                    bare
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (
                        window.confirm(
                          `Delete a draft variant "${menu.title}"?`,
                        )
                      ) {
                        deleteExplainQuery({
                          variables: { explainQueryId: menu.variantId },
                          refetchQueries: [
                            {
                              query: WORKBOOK_DETAIL_QUERY,
                              variables: {
                                workbookId: workbook.id,
                                databaseId: workbook.databaseId,
                              },
                            },
                          ],
                          awaitRefetchQueries: true,
                        });
                      }
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      title="Delete"
                      className="text-[#CA1515]"
                    />
                  </Button>
                )}
            </div>
          </NavLink>
        );
      })}
    </div>
  );
};

const ExplainVariantSidebar = ({
  workbook,
  explainQuery,
  permittedToTuneQueries,
}: {
  workbook: ExplainWorkbookType;
  explainQuery?: ExplainQueryType;
  permittedToTuneQueries: boolean;
}) => {
  const { databaseId } = useParams();
  const { databaseQuery, databaseWorkbookVariants } = useRoutes();
  const navigate = useNavigate();
  const [showEdit, setShowEdit] = useState(false);
  const focusedQuery = explainQuery || workbook.baselineQuery;
  const baseline = focusedQuery.id === workbook.baselineQuery.id;
  const [deleteExplainQuery] = useMutation<
    DeleteExplainQuery,
    DeleteExplainQueryVariables
  >(VARIANT_MUTATION);

  const handleDismissEdit = () => {
    setShowEdit(false);
  };

  const handleEditVariant = () => {
    setShowEdit(true);
  };

  const handleDeleteVariant = () => {
    if (window.confirm(`Delete variant "${explainQuery.name}"?`)) {
      deleteExplainQuery({
        variables: { explainQueryId: explainQuery.id },
        refetchQueries: [
          {
            query: WORKBOOK_DETAIL_QUERY,
            variables: {
              workbookId: workbook.id,
              databaseId: workbook.databaseId,
            },
          },
        ],
        awaitRefetchQueries: true,
        onCompleted: () => {
          navigate(databaseWorkbookVariants(databaseId, workbook.id));
        },
      });
    }
  };

  const queryTags: QueryTagType[] = [];
  if (focusedQuery.query) {
    queryTags.push(
      ...[
        {
          id: "fingerprint",
          key: (
            <Popover
              content="A query fingerprint represents the abstracted form of a query and enables the grouping of similar queries together. This value is calculated using the pg_query library."
              popupClassName="!text-[12px]"
            >
              <FontAwesomeIcon
                icon={faFingerprint}
                className="text-[#666] text-[10px]"
              />{" "}
              fingerprint
            </Popover>
          ),
          value: focusedQuery.queryFingerprint,
        },
        {
          id: "role",
          key: (
            <>
              <FontAwesomeIcon
                icon={faUser}
                className="text-[#666] text-[10px]"
              />{" "}
              role
            </>
          ),
          value: focusedQuery.query.postgresRole.name,
        },
      ],
    );
  }

  return (
    <div className="w-[300px]">
      <SidebarNav
        workbook={workbook}
        permittedToTuneQueries={permittedToTuneQueries}
      />
      <hr />
      <div className="leading-7 mt-0 font-medium">Query</div>
      <div className="mb-3">
        {focusedQuery.query ? (
          <Link to={databaseQuery(databaseId, focusedQuery.query.id)}>
            #{focusedQuery.query.id}
          </Link>
        ) : (
          <span className="italic text-[#606060]">
            No match with existing queries
          </span>
        )}
      </div>
      <div className="leading-7 mt-0 font-medium">Query tags</div>
      <div className="mb-3">
        {queryTags.length > 0 ? (
          <QueryTags tags={queryTags} />
        ) : (
          <span className="italic text-[#606060]">No query tags</span>
        )}
      </div>
      {!baseline && (
        <>
          <div className="leading-7 mt-0 font-medium">Variant description</div>
          <div className="mb-2">
            {focusedQuery.description ?? (
              <span className="italic text-[#606060]">No description</span>
            )}
          </div>
        </>
      )}
      <hr />
      {!baseline && (
        <div className="flex flex-col gap-3 items-start mb-8">
          <Button bare onClick={handleEditVariant} className="!text-[#337ab7]">
            <FontAwesomeIcon icon={faEdit} /> Edit variant name and description
          </Button>
          <Button
            bare
            onClick={handleDeleteVariant}
            className="!text-[#d43f3a]"
          >
            <FontAwesomeIcon icon={faTrashAlt} /> Remove variant
          </Button>
        </div>
      )}
      {showEdit && (
        <VariantEditPanel
          variant={focusedQuery}
          onDismiss={handleDismissEdit}
        />
      )}
    </div>
  );
};

function VariantEditPanel({
  variant,
  onDismiss,
}: {
  variant: ExplainQueryType;
  onDismiss: () => void;
}) {
  const [name, setName] = useState(variant.name);
  const [description, setDescription] = useState(variant.description);

  return (
    <VariantNameEditPanel
      onDismiss={onDismiss}
      name={name}
      description={description}
      setName={setName}
      setDescription={setDescription}
      variant={variant}
    />
  );
}

export default ExplainVariantSidebar;
