import React, { useState } from "react";
import SQL from "components/SQL";
import SQLNew from "components/SQLNew";
import { QueryDetails_getQueryDetails_normalizedQueryScanTokens as QueryScanToken } from "components/QueryDetails/types/QueryDetails";

import {
  faArrowsMaximize,
  faArrowsMinimize,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const EXPAND_CUTOFF = 200;

type Props = {
  sql: string;
  databaseId?: string;
  scanTokens?: QueryScanToken[] | null;
};

const ExpandableSQL: React.FunctionComponent<Props> = ({
  sql,
  databaseId,
  scanTokens,
}) => {
  const threeNewlines = /(?:.*\n){3}/;
  const expandable = sql.length > EXPAND_CUTOFF || threeNewlines.test(sql);

  const [expanded, setExpanded] = useState(false);
  const handleExpand = (
    evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    evt.preventDefault();
    setExpanded((expanded) => !expanded);
  };

  let maybeTruncatedSql = sql;
  if (expandable && !expanded) {
    const truncated =
      sql.length > EXPAND_CUTOFF
        ? sql.substring(0, EXPAND_CUTOFF)
        : sql.match(threeNewlines)[0];

    maybeTruncatedSql = truncated + "...";
  }
  const hasMultibyteChars = /[^\u0000-\u007f]/.test(sql);

  return (
    <>
      {databaseId != null && scanTokens != null && !hasMultibyteChars ? (
        <SQLNew
          queryText={maybeTruncatedSql}
          databaseId={databaseId}
          scanTokens={scanTokens}
        />
      ) : (
        <SQL sql={maybeTruncatedSql} />
      )}
      {expandable && (
        <div className="pt-2">
          <a href="" onClick={handleExpand}>
            <FontAwesomeIcon
              icon={expanded ? faArrowsMinimize : faArrowsMaximize}
            />{" "}
            {expanded ? "Hide" : "Show"} full query text
          </a>
        </div>
      )}
    </>
  );
};

export default ExpandableSQL;
