import React from "react";

import classNames from "classnames";

import {
  matchPath,
  useLocation,
  useResolvedPath,
  Link,
} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArchive,
  faBell,
  faBooks,
  faChartLineUp,
  faCircleNotch,
  faCog,
  faExchange,
  faHdd,
  faRadar,
  faRocket,
  faTable,
  faWrench,
  IconDefinition,
} from "@fortawesome/pro-solid-svg-icons";

import { useRoutes } from "utils/routes";

import styles from "./style.module.scss";
import { useFallbackServerId } from "components/WithCurrentOrganization";

type Props = {
  organizationSlug?: string;
  serverId?: string;
  databaseId?: string;
};

const NavItems: React.FunctionComponent<Props> = ({
  organizationSlug,
  databaseId,
  serverId,
}) => {
  const {
    root,
    database,
    databaseIndexAdvisor,
    databaseQueries,
    databaseWorkbooks,
    databaseTables,
    databaseLogs,
    databaseBackends,
    databaseChecks,
    server,
    serverQueries,
    serverWorkbooks,
    serverSchema,
    serverIndexAdvisor,
    serverLogs,
    serverConnections,
    serverVacuums,
    serverConfig,
    serverSystem,
    serverEdit,
    serverChecks,
    organization,
    organizationServersSettings,
    organizationChecks,
  } = useRoutes();

  const fallbackServerId = useFallbackServerId();

  if (!organizationSlug && !databaseId && !serverId) {
    return (
      <NavItem
        to={root()}
        icon={faRocket}
        matchPaths={["/", "/organizations", "/servers", "/databases"]}
      >
        Dashboard
      </NavItem>
    );
  }

  const pickRoute = (
    serverRoute: (serverId: string) => string,
    databaseRoute?: (databaseId: string) => string,
    organizationRoute?: (organizationSlug: string) => string,
  ) => {
    return databaseId && databaseRoute
      ? databaseRoute(databaseId)
      : serverId
      ? serverRoute(serverId)
      : organizationRoute
      ? organizationRoute(organizationSlug)
      : fallbackServerId
      ? serverRoute(fallbackServerId)
      : organization(organizationSlug);
  };

  return (
    <>
      <NavItem
        to={
          databaseId
            ? database(databaseId)
            : serverId
            ? server(serverId)
            : organization(organizationSlug)
        }
        icon={faRocket}
        matchPaths={[
          "/organizations/:orgSlug",
          "/organizations/:orgSlug/servers",
          "/organizations/:orgSlug/databases",
          "/servers/:serverId",
          "/databases/:databaseId",
        ]}
      >
        Dashboard
      </NavItem>
      <NavItem
        to={pickRoute(serverQueries, databaseQueries)}
        icon={faChartLineUp}
        matchPaths={[
          "/servers/:serverId/queries",
          "/databases/:databaseId/queries",
          "/databases/:databaseId/queries/:queryId/*",
        ]}
      >
        Query Performance
      </NavItem>
      <NavItem
        to={pickRoute(serverWorkbooks, databaseWorkbooks)}
        icon={faBooks}
        matchPaths={[
          "/servers/:serverId/workbooks",
          "/databases/:databaseId/workbooks",
          "/servers/:serverId/workbooks/*",
          "/databases/:databaseId/workbooks/*",
        ]}
      >
        Query Tuning
      </NavItem>
      <NavItem
        to={pickRoute(serverIndexAdvisor, databaseIndexAdvisor)}
        icon={faRadar}
        matchPaths={[
          "/databases/:databaseId/indexadvisor",
          "/databases/:databaseId/indexadvisor/indexing_engine",
          "/databases/:databaseId/indexadvisor/index_unused",
          "/databases/:databaseId/indexadvisor/config",
          "/databases/:databaseId/indexadvisor/status",
        ]}
      >
        Index Advisor
      </NavItem>
      <NavItem
        to={pickRoute(serverVacuums)}
        icon={faCircleNotch}
        matchPaths={["/servers/:serverId/vacuums/*"]}
      >
        VACUUM Advisor
      </NavItem>
      <NavItem
        to={pickRoute(serverSchema, databaseTables)}
        icon={faTable}
        matchPaths={[
          "/servers/:serverId/schema",
          "/databases/:databaseId/tables/*",
          "/databases/:databaseId/indexes/*",
          "/databases/:databaseId/views/*",
          "/databases/:databaseId/functions/*",
        ]}
      >
        Schema Statistics
      </NavItem>
      <NavItem
        to={pickRoute(serverLogs, databaseLogs)}
        icon={faArchive}
        matchPaths={[
          "/servers/:serverId/logs/*",
          "/databases/:databaseId/logs/*",
        ]}
      >
        Log Insights
      </NavItem>
      <NavItem
        to={pickRoute(serverConnections, databaseBackends)}
        icon={faExchange}
        matchPaths={[
          "/servers/:serverId/connections",
          "/databases/:databaseId/backends/*",
          "/databases/:databaseId/wait_events",
        ]}
      >
        Connections
      </NavItem>
      <NavItem
        to={pickRoute(serverConfig)}
        icon={faWrench}
        matchPaths={["/servers/:serverId/config/*"]}
      >
        Config Settings
      </NavItem>
      <NavItem
        to={pickRoute(serverSystem)}
        icon={faHdd}
        matchPaths={[
          "/servers/:serverId/system/*",
          "/servers/:serverId/roles/*",
          "/servers/:serverId/replication/*",
        ]}
      >
        System
      </NavItem>
      <NavItem
        to={pickRoute(serverChecks, databaseChecks, organizationChecks)}
        icon={faBell}
        matchPaths={[
          "/organizations/:orgSlug/alerts/*",
          "/organizations/:orgSlug/checks/*",
          "/servers/:serverId/alerts/*",
          "/servers/:serverId/checks/*",
          "/databases/:databaseId/alerts/*",
          "/databases/:databaseId/checks/*",
        ]}
      >
        Alerts & Check-Up
      </NavItem>
      <NavItem
        to={pickRoute(serverEdit, null, organizationServersSettings)}
        icon={faCog}
        matchPaths={[
          "/servers/:serverId/edit",
          "/servers/:serverId/snapshots/*",
          "/organizations/:orgSlug/servers/settings/*",
          "/organizations/:orgSlug/members/*",
          "/organizations/:orgSlug/roles/*",
          "/organizations/:orgSlug/api/*",
          "/organizations/:orgSlug/subscription/*",
          "/organizations/:orgSlug/edit/*",
        ]}
      >
        Settings
      </NavItem>
    </>
  );
};

type ItemProps = {
  icon: IconDefinition;
  children: React.ReactNode;
  to: string;
  matchPaths: string[];
  matchPathsExclude?: string[];
  className?: string;
};

const NavItem: React.FunctionComponent<ItemProps> = ({
  icon,
  children,
  to,
  matchPaths,
  matchPathsExclude,
  className,
}) => {
  const locationPathname = useLocation().pathname.toLowerCase();
  const toPathname = useResolvedPath(to).pathname.toLowerCase();

  const isActive =
    locationPathname === toPathname ||
    (matchPaths.findIndex((p) => matchPath(p, locationPathname)) != -1 &&
      (!matchPathsExclude ||
        matchPathsExclude.findIndex((p) => matchPath(p, locationPathname)) ==
          -1));

  return (
    <li className={classNames(styles.menuItem, className)}>
      <Link
        aria-current={isActive ? "page" : undefined}
        className={isActive ? styles.activeMenuItemLink : styles.menuItemLink}
        to={to}
      >
        <FontAwesomeIcon icon={icon} />
        <span>{children}</span>
      </Link>
    </li>
  );
};

export default NavItems;
