import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";

import { useMutation } from "@apollo/client";

import ModalContainer from "components/ModalContainer";

import { ExplainWorkbookDetails_getExplainWorkbookDetails_explainQueries as ExplainQueryType } from "../ExplainWorkbook/types/ExplainWorkbookDetails";

import {
  UpdateExplainQuery,
  UpdateExplainQueryVariables,
} from "./types/UpdateExplainQuery";

import UPDATE_MUTATION from "./Mutation.update.graphql";
import WORKBOOK_DETAIL_QUERY from "../ExplainWorkbook/Query.graphql";
import { useParams } from "react-router-dom";

const VariantNameEditPanel = ({
  onDismiss,
  name,
  description,
  setName,
  setDescription,
  variant,
}: {
  onDismiss: () => void;
  name: string;
  description: string;
  setName: (name: string) => void;
  setDescription: (desc: string) => void;
  variant?: ExplainQueryType;
}) => {
  const { databaseId, workbookId } = useParams();
  const [errorMessage, setErrorMessage] = useState("");

  const [updateExplainQuery] = useMutation<
    UpdateExplainQuery,
    UpdateExplainQueryVariables
  >(UPDATE_MUTATION);

  const handleUpdateVariant = () => {
    if (name === "") {
      setErrorMessage("Name is required");
      return;
    }
    updateExplainQuery({
      variables: {
        explainQueryId: variant.id,
        name: name,
        description: description,
      },
      refetchQueries: [
        {
          query: WORKBOOK_DETAIL_QUERY,
          variables: {
            workbookId,
            databaseId,
          },
        },
      ],
      onError: (error) => {
        setErrorMessage(error.message);
      },
      onCompleted: () => {
        onDismiss();
      },
    });
  };

  return (
    <ModalContainer title="Edit Variant" onClose={onDismiss} layout="centered">
      <div className="grid gap-4">
        <div className="grid gap-1">
          <div className="font-medium">Name</div>
          <input
            className="bg-white rounded border border-gray-300 box-content h-5 leading-5 px-2 py-1.5"
            type="text"
            onChange={(e) => {
              setName(e.target.value);
              setErrorMessage("");
            }}
            value={name}
            placeholder="Name of variant"
          />
        </div>
        <div className="grid gap-1">
          <div className="font-medium">Description (Optional)</div>
          <textarea
            className="bg-white rounded border border-gray-300 box-content leading-5 px-2 py-1.5"
            onChange={(e) => {
              setDescription(e.target.value);
              setErrorMessage("");
            }}
            value={description}
            placeholder="Enter description here"
          />
        </div>
        {errorMessage && (
          <div className="text-[#C22426]">
            <FontAwesomeIcon icon={faExclamationCircle} /> {errorMessage}
          </div>
        )}
        <div>
          <button
            className="btn btn-success"
            onClick={variant ? handleUpdateVariant : onDismiss}
          >
            Update
          </button>
        </div>
      </div>
    </ModalContainer>
  );
};

export default VariantNameEditPanel;
