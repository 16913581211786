import React from "react";
import { graphql } from "@apollo/client/react/hoc";
import flowRight from "lodash/flowRight";

import {
  ViewPermission,
  ModifyPermission,
  BillingPermission,
  APIPermission,
  ManagePermission,
  TuneQueriesPermission,
} from "components/DocsSnippet";
import FormTextField from "components/FormTextField";
import withLoading from "decorators/withLoading";

import styles from "./style.module.scss";
import QUERY from "./Query.graphql";

type PermissionType = {
  id: string;
  databaseId: string | null | undefined;
  serverId: string | null | undefined;
  view: boolean;
  modify: boolean;
  billing: boolean;
  api: boolean;
  manage: boolean;
  tuneQueries: boolean;
};

type DatabaseType = {
  id: string;
  displayName: string;
};

type ServerType = {
  id: string;
  name: string;
};

type Props = {
  data: {
    getDatabases: Array<DatabaseType>;
    getServers: Array<ServerType>;
  };
  roleName?: string;
  permissions: Array<PermissionType>;
};

type State = {
  newPermissions: Array<PermissionType>;
  newPermissionIds: Array<string>;
  deletedPermissionIds: Array<string>;
  restrictedByServer: {
    [a: string]: boolean;
  };
  restrictedByDatabase: {
    [a: string]: boolean;
  };
};

class RoleForm extends React.Component<Props, State> {
  static defaultProps: { permissions: number[] } = {
    permissions: [],
  };

  constructor(props: Props) {
    super(props);

    const restrictedByServer: { [key: string]: boolean } = {};
    const restrictedByDatabase: { [key: string]: boolean } = {};
    for (const p of this.props.permissions) {
      restrictedByServer[p.id] = !!p.serverId;
      restrictedByDatabase[p.id] = !!p.databaseId;
    }

    this.state = {
      newPermissions: [],
      newPermissionIds: [],
      deletedPermissionIds: [],
      restrictedByServer,
      restrictedByDatabase,
    };
  }

  componentDidMount() {
    if (this.props.permissions.length === 0) {
      this.addPermission();
    }
  }

  render() {
    const { roleName, permissions } = this.props;

    return (
      <div className={styles.container}>
        <FormTextField
          name="role[name]"
          required={true}
          defaultValue={roleName}
          label="Name"
        />
        <table>
          <thead>
            <tr>
              <th className={styles.restriction}>Server</th>
              <th className={styles.restriction}>Database</th>
              <th className={styles.permission}>
                View <ViewPermission />
              </th>
              <th className={styles.permission}>
                Modify <ModifyPermission />
              </th>
              <th className={styles.permission}>
                Billing <BillingPermission />
              </th>
              <th className={styles.permission}>
                API <APIPermission />
              </th>
              <th className={styles.permission}>
                Manage <ManagePermission />
              </th>
              <th className={styles.permission}>
                Tune Queries <TuneQueriesPermission />
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {(permissions || [])
              .concat(this.state.newPermissions)
              .map(
                (p: PermissionType): React.ReactNode =>
                  this.renderPermission(p),
              )}
          </tbody>
        </table>
        {this.state.deletedPermissionIds.map(
          (pId: string): React.ReactNode => this.renderDeletedPermission(pId),
        )}
        <a
          onClick={(evt) => {
            evt.preventDefault();
            this.addPermission();
          }}
          href=""
        >
          Add Permission
        </a>
      </div>
    );
  }

  renderPermission(permission: PermissionType) {
    if (this.state.deletedPermissionIds.indexOf(permission.id) !== -1) {
      return null;
    }

    const isNew = this.state.newPermissionIds.indexOf(permission.id) !== -1;
    const restricted =
      this.state.restrictedByServer[permission.id] ||
      this.state.restrictedByDatabase[permission.id];

    return (
      <tr key={permission.id}>
        <td className={styles.restriction}>
          <select
            name={`role[permissions_attributes][${permission.id}][server_id]`}
            id={`role_permissions_attributes_${permission.id}_server_id`}
            defaultValue={permission.serverId}
            className="form-control"
            onChange={(evt) => {
              this.setRestrictedByServer(
                permission.id,
                !!evt.currentTarget.value,
              );
            }}
          >
            <option value="">All</option>
            <option value="" disabled>
              ---
            </option>
            {this.state.restrictedByDatabase[permission.id] ? (
              <option value="" disabled>
                Either choose server or database
              </option>
            ) : (
              this.props.data.getServers.map(
                (s: ServerType): React.ReactNode => (
                  <option key={s.id} value={s.id}>
                    {s.name}
                  </option>
                ),
              )
            )}
          </select>
        </td>
        <td>
          <select
            name={`role[permissions_attributes][${permission.id}][database_id]`}
            id={`role_permissions_attributes_${permission.id}_database_id`}
            defaultValue={permission.databaseId}
            className="form-control"
            onChange={(evt) => {
              this.setRestrictedByDatabase(
                permission.id,
                !!evt.currentTarget.value,
              );
            }}
          >
            <option value="">All</option>
            <option value="" disabled>
              ---
            </option>
            {this.state.restrictedByServer[permission.id] ? (
              <option value="" disabled>
                Either choose server or database
              </option>
            ) : (
              this.props.data.getDatabases.map(
                (d: DatabaseType): React.ReactNode => (
                  <option key={d.id} value={d.id}>
                    {d.displayName}
                  </option>
                ),
              )
            )}
          </select>
        </td>
        <td className={styles.permission}>
          <input
            name={`role[permissions_attributes][${permission.id}][view]`}
            type="hidden"
            value="0"
          />
          <input
            name={`role[permissions_attributes][${permission.id}][view]`}
            id={`role_permissions_attributes_${permission.id}_view`}
            type="checkbox"
            value="1"
            defaultChecked={permission.view}
          />
        </td>
        <td className={styles.permission}>
          <input
            name={`role[permissions_attributes][${permission.id}][modify]`}
            type="hidden"
            value="0"
          />
          <input
            name={`role[permissions_attributes][${permission.id}][modify]`}
            id={`role_permissions_attributes_${permission.id}_modify`}
            type="checkbox"
            value="1"
            defaultChecked={permission.modify}
          />
        </td>
        <td className={styles.permission}>
          <input
            name={`role[permissions_attributes][${permission.id}][billing]`}
            type="hidden"
            value="0"
          />
          {!restricted && (
            <input
              name={`role[permissions_attributes][${permission.id}][billing]`}
              id={`role_permissions_attributes_${permission.id}_billing`}
              type="checkbox"
              value="1"
              defaultChecked={permission.billing}
            />
          )}
        </td>
        <td className={styles.permission}>
          <input
            name={`role[permissions_attributes][${permission.id}][api]`}
            type="hidden"
            value="0"
          />
          {!restricted && (
            <input
              name={`role[permissions_attributes][${permission.id}][api]`}
              id={`role_permissions_attributes_${permission.id}_api`}
              type="checkbox"
              value="1"
              defaultChecked={permission.api}
            />
          )}
        </td>
        <td className={styles.permission}>
          <input
            name={`role[permissions_attributes][${permission.id}][manage]`}
            type="hidden"
            value="0"
          />
          {!restricted && (
            <input
              name={`role[permissions_attributes][${permission.id}][manage]`}
              id={`role_permissions_attributes_${permission.id}_manage`}
              type="checkbox"
              value="1"
              defaultChecked={permission.manage}
            />
          )}
        </td>
        <td className={styles.permission}>
          <input
            name={`role[permissions_attributes][${permission.id}][tune_queries]`}
            type="hidden"
            value="0"
          />
          <input
            name={`role[permissions_attributes][${permission.id}][tune_queries]`}
            id={`role_permissions_attributes_${permission.id}_tune_queries`}
            type="checkbox"
            value="1"
            defaultChecked={permission.tuneQueries}
          />
        </td>
        <td>
          {!isNew && (
            <input
              type="hidden"
              name={`role[permissions_attributes][${permission.id}][id]`}
              value={permission.id}
            />
          )}
          <a
            onClick={(evt) => {
              evt.preventDefault();
              this.deletePermission(permission.id);
            }}
            href=""
          >
            Delete
          </a>
        </td>
      </tr>
    );
  }

  renderDeletedPermission(permissionId: string): React.ReactNode {
    if (this.state.newPermissionIds.indexOf(permissionId) !== -1) {
      return null;
    }

    return (
      <span key={permissionId}>
        <input
          type="hidden"
          name={`role[permissions_attributes][${permissionId}][id]`}
          value={permissionId}
        />
        <input
          type="hidden"
          name={`role[permissions_attributes][${permissionId}][_destroy]`}
          value="1"
        />
      </span>
    );
  }

  addPermission() {
    const newPermission: PermissionType = {
      id: "new-" + this.state.newPermissions.length,
      databaseId: null,
      serverId: null,
      view: false,
      modify: false,
      billing: false,
      api: false,
      manage: false,
      tuneQueries: false,
    };

    this.setState({
      newPermissions: this.state.newPermissions.concat([newPermission]),
      newPermissionIds: this.state.newPermissionIds.concat([newPermission.id]),
    });
  }

  deletePermission(permissionId: string) {
    this.setState({
      deletedPermissionIds: this.state.deletedPermissionIds.concat([
        permissionId,
      ]),
    });
  }

  setRestrictedByServer(permissionId: string, restricted: boolean) {
    this.setState({
      restrictedByServer: Object.assign({}, this.state.restrictedByServer, {
        [permissionId]: restricted,
      }),
    });
  }

  setRestrictedByDatabase(permissionId: string, restricted: boolean) {
    this.setState({
      restrictedByDatabase: Object.assign({}, this.state.restrictedByDatabase, {
        [permissionId]: restricted,
      }),
    });
  }
}

export default flowRight(graphql(QUERY), withLoading)(RoleForm);
