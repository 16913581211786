import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import moment from "moment-timezone";

import { groupBy } from "lodash";

import ExplainTable from "components/ExplainTable";
import Loading from "components/Loading";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import QueryTags, { QueryTagType } from "components/QueryTags";
import SQL from "components/SQL";

import {
  ExplainList as ExplainListType,
  ExplainListVariables,
  ExplainList_getExplains,
} from "./types/ExplainList";

import QueryExplainsBlankSlate from "components/QueryExplainsBlankSlate";
import { useRoutes } from "utils/routes";
import LogProcessingDisabledPanel from "components/LogProcessingDisabledPanel";

import QUERY from "./Query.graphql";

interface ExplainGroupProps {
  serverId: string;
  explains: ExplainList_getExplains[];
  queryId: string;
  blockSize: number;
}

const ExplainGroup: React.FunctionComponent<ExplainGroupProps> = ({
  serverId,
  explains,
  blockSize,
}) => {
  const { serverRole, databaseQueryExplains } = useRoutes();
  const explain = explains[0];
  const mostRecentlySeenAt = explains
    .slice()
    .sort((e1, e2) => e2.seenAt - e1.seenAt)[0].seenAt;
  // for now, we're just taking tags from the first sample that has any;
  // may want to merge them? use latest?
  const explainWithQuerySample = explains.find(
    (e) => e.querySample && e.querySample.queryTagset,
  );
  const querySample =
    explainWithQuerySample && explainWithQuerySample.querySample;
  const postgresRole = explains.every(
    (explain) => explain.postgresRole === explains[0].postgresRole,
  )
    ? explains[0].postgresRole
    : undefined;

  const tags: Array<QueryTagType> = [];
  if (querySample && querySample.queryTagset) {
    const commentData = JSON.parse(querySample.queryTagset.commentData);
    for (const key in commentData) {
      const value = commentData[key];
      tags.push({
        id: key,
        key: key,
        value: typeof value === "string" ? value : JSON.stringify(value),
      });
    }
  }

  const secondaryTitle = (
    <span>
      {postgresRole && (
        <span>
          Role:&nbsp;
          <Link to={serverRole(serverId, postgresRole.id)}>
            {postgresRole.name}
          </Link>
          &nbsp;·&nbsp;
        </span>
      )}
      {moment.unix(mostRecentlySeenAt).format("lll z")}
    </span>
  );

  return (
    <Panel
      title={`EXPLAINs for Query #${explain.query.id}`}
      secondaryTitle={secondaryTitle}
    >
      <PanelSection>
        <SQL sql={explain.query.truncatedQuery} />
        {tags.length > 0 && <QueryTags tags={tags} />}
      </PanelSection>
      <ExplainTable
        databaseId={explain.database.id}
        explains={explains}
        blockSize={blockSize}
      />
      <PanelSection>
        <Link
          to={databaseQueryExplains(explain.database.id, explain.query.id)}
          className="btn btn-success"
        >
          Show Plan
        </Link>
      </PanelSection>
    </Panel>
  );
};

const ExplainList: React.FunctionComponent = () => {
  const { databaseId, serverId } = useParams();
  const { loading, error, data } = useQuery<
    ExplainListType,
    ExplainListVariables
  >(QUERY, {
    variables: { databaseId, serverId },
  });

  if ((loading && !data?.getExplains) || error) {
    return <Loading error={!!error} />;
  }
  const server = data.getServerDetails;
  const blockSize = server.blockSize;

  let warning;
  if (server.collectorInfo?.logCollectionDisabled) {
    warning = (
      <LogProcessingDisabledPanel
        disabledReasons={server.collectorInfo?.logCollectionDisabledReason}
      />
    );
  }

  const content =
    data.getExplains.length === 0
      ? warning || <QueryExplainsBlankSlate serverId={server.humanId} />
      : Object.entries<ExplainList_getExplains[]>(
          groupBy(data.getExplains, (explain) => explain.query.id),
        ).map(([queryId, explains]) => (
          <ExplainGroup
            key={queryId}
            serverId={server.humanId}
            explains={explains}
            queryId={queryId}
            blockSize={blockSize}
          />
        ));

  return <>{content}</>;
};

export default ExplainList;
