import classNames from "classnames";
import Button from "components/Button";
import React from "react";
import { Link } from "react-router-dom";

type ButtonBarOpt<T extends string> = {
  label: string;
  value: T;
};

function PillButtonBar<T extends string>({
  opts,
  selected,
  onChange,
  mode = "default",
}: {
  opts: readonly ButtonBarOpt<T>[];
  selected: T;
  onChange?: (value: T) => void;
  mode?: "default" | "stretch";
}) {
  const cx = classNames(
    "block rounded-full !py-1 text-inherit hover:text-inherit focus:text-inherit",
    mode === "stretch" ? "w-full" : "!px-4",
  );

  return (
    <div className="flex gap-4">
      {opts.map((opt) => {
        return (
          <div
            key={opt.value}
            className={classNames(
              "whitespace-nowrap rounded-md font-medium text-center",
              opt.value === selected
                ? "bg-[#AAECA8] text-[#19152C] hover:bg-[#B8EAAE]"
                : "bg-transparent text-[#606060] hover:text-[#19152C]",
              mode === "stretch" ? "flex-auto" : "",
            )}
          >
            {onChange ? (
              <Button className={cx} bare onClick={() => onChange(opt.value)}>
                {opt.label}
              </Button>
            ) : (
              <Link className={cx} to={opt.value}>
                {opt.label}
              </Link>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default PillButtonBar;
