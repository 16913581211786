import React from "react";

import { Link, useParams } from "react-router-dom";
import sortBy from "lodash/sortBy";
import { useQuery } from "@apollo/client";

import { useRoutes } from "utils/routes";
import { groupPermissions, PermissionType } from "utils/permissions";
import {
  ViewPermission,
  ModifyPermission,
  BillingPermission,
  APIPermission,
  ManagePermission,
  TuneQueriesPermission,
} from "components/DocsSnippet";
import PageContent from "components/PageContent";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import PanelTable from "components/PanelTable";
import SettingsNav from "components/SettingsNav";
import Loading from "components/Loading";

import styles from "./style.module.scss";

import { RoleList as RoleListType, RoleListVariables } from "./types/RoleList";
import QUERY from "./Query.graphql";
import PermissionIcon from "components/PermissionIcon";

type OrganizationRoleType = {
  id: string;
  name: string;
  permissions: PermissionType[];
  assignmentCount: number;
};

const RoleList: React.FunctionComponent = () => {
  const { slug: organizationSlug } = useParams();
  const { organizationRoleNew } = useRoutes();
  const { data, loading, error } = useQuery<RoleListType, RoleListVariables>(
    QUERY,
    {
      variables: { organizationSlug },
    },
  );

  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const { permittedToCreateRoles } = data.getOrganizationDetails;

  const roles = sortBy(
    data.getOrganizationRoles,
    (k: OrganizationRoleType): string => k.name,
  );

  return (
    <PageContent
      title="Settings"
      pageCategory="organization-roles"
      pageName="index"
      featureNav={<SettingsNav />}
    >
      <Panel title="Roles">
        {roles.length > 0 && (
          <PanelTable>
            <thead>
              <tr>
                <th>Name</th>
                <th className={styles.assignmentCount}>Members</th>
                <th className={styles.permission}>
                  View <ViewPermission />
                </th>
                <th className={styles.permission}>
                  Modify <ModifyPermission />
                </th>
                <th className={styles.permission}>
                  Billing <BillingPermission />
                </th>
                <th className={styles.permission}>
                  API <APIPermission />
                </th>
                <th className={styles.permission}>
                  Manage <ManagePermission />
                </th>
                <th className={styles.permission}>
                  Tune Queries <TuneQueriesPermission />
                </th>
              </tr>
            </thead>
            <tbody>
              {roles.map((r) => (
                <OrganizationRole
                  key={r.id}
                  role={r}
                  organizationSlug={organizationSlug}
                />
              ))}
            </tbody>
          </PanelTable>
        )}
        {permittedToCreateRoles && (
          <PanelSection>
            <Link
              to={organizationRoleNew(organizationSlug)}
              className="btn btn-success"
            >
              New Role
            </Link>
          </PanelSection>
        )}
        {!permittedToCreateRoles && (
          <PanelSection>
            You are not allowed to create new roles - you do not have the{" "}
            <strong>Manage</strong> permission on this organization.
          </PanelSection>
        )}
      </Panel>
    </PageContent>
  );
};

const OrganizationRole: React.FunctionComponent<{
  role: OrganizationRoleType;
  organizationSlug: string;
}> = ({ role, organizationSlug }) => {
  const { id, name, permissions, assignmentCount } = role;
  const { organizationRole } = useRoutes();
  return (
    <tr key={id}>
      <td>
        <Link to={organizationRole(organizationSlug, id)}>{name}</Link>
      </td>
      <td className={styles.assignmentCount}>{assignmentCount}</td>
      <td className={styles.permission}>
        <PermissionIcon permission={groupPermissions(permissions, "view")} />
      </td>
      <td className={styles.permission}>
        <PermissionIcon permission={groupPermissions(permissions, "modify")} />
      </td>
      <td className={styles.permission}>
        <PermissionIcon permission={groupPermissions(permissions, "billing")} />
      </td>
      <td className={styles.permission}>
        <PermissionIcon permission={groupPermissions(permissions, "api")} />
      </td>
      <td className={styles.permission}>
        <PermissionIcon permission={groupPermissions(permissions, "manage")} />
      </td>
      <td className={styles.permission}>
        <PermissionIcon
          permission={groupPermissions(permissions, "tuneQueries")}
        />
      </td>
    </tr>
  );
};

export default RoleList;
