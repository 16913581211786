import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";

import QUERY from "./Query.graphql";
import MUTATION from "./Mutation.graphql";
import {
  QueryForWorkbook,
  QueryForWorkbookVariables,
} from "./types/QueryForWorkbook";
import {
  CreateExplainWorkbook,
  CreateExplainWorkbookVariables,
} from "./types/CreateExplainWorkbook";
import Loading from "components/Loading";
import Panel from "components/Panel";
import PageContent from "components/PageContent";
import PanelSection from "components/PanelSection";
import Callout from "components/Callout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";
import SQL from "components/SQL";
import { useRoutes } from "utils/routes";
import classNames from "classnames";
import { CreateSteps, WorkbookCreationHeader } from "../ChooseParameters";
import CopyToClipboard from "components/CopyToClipboard";

export type LocationState = {
  queryText: string;
  name: string;
  description: string;
};

const ReviewQuery = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryId = searchParams.get("queryId");
  const { databaseId } = useParams();
  const [errorMessage, setErrorMessage] = useState("");
  const state = location.state as LocationState;
  const [query, setQuery] = useState(state?.queryText ?? "");
  const [name, setName] = useState(
    state?.name ?? (queryId ? `Query #${queryId}` : "New workbook"),
  );
  const [description, setDescription] = useState(state?.description ?? "");
  const [queryEdit, setQueryEdit] = useState(false);
  const navigate = useNavigate();
  const { databaseWorkbookVariants, databaseWorkbooks, databaseQuery } =
    useRoutes();

  const [createWorkbook] = useMutation<
    CreateExplainWorkbook,
    CreateExplainWorkbookVariables
  >(MUTATION);
  const [getQuery, { loading, error, data }] = useLazyQuery<
    QueryForWorkbook,
    QueryForWorkbookVariables
  >(QUERY);

  useEffect(() => {
    if (queryId) {
      getQuery({
        variables: { databaseId, queryId },
        onCompleted: (data) => {
          setQuery(data.getQueryForWorkbook.normalizedQuery);
          if (!data.getQueryForWorkbook.validQuery) {
            setErrorMessage(data.getQueryForWorkbook.errorMessage);
          }
        },
        onError: (error) => {
          setErrorMessage(error.message);
        },
      });
    }
  }, [databaseId, queryId, getQuery]);

  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const handleCheckQuery = () => {
    if (query.trim() === "") {
      setErrorMessage("Query text is required");
      return;
    }
    getQuery({
      variables: { databaseId, queryText: query },
      onCompleted: (data) => {
        setQueryEdit(false);
        if (data.getQueryForWorkbook.validQuery) {
          setErrorMessage("");
        } else {
          setErrorMessage(data.getQueryForWorkbook.errorMessage);
        }
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
    });
  };

  const handleCreate = () => {
    if (name === "") {
      setErrorMessage("Name is required");
      return;
    }
    if (query.trim() === "") {
      setErrorMessage("Query text is required");
      return;
    }
    if (!validQuery) {
      setErrorMessage("Query is not valid");
      return;
    }
    createWorkbook({
      variables: {
        databaseId: databaseId,
        name: name,
        queryText: query,
        description: description || null,
      },
      onCompleted: (data) => {
        navigate(
          databaseWorkbookVariants(
            databaseId,
            data.createExplainWorkbook.explainWorkbookId,
          ),
        );
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
    });
  };

  function handleUpdateQuery(newQuery: string) {
    setQueryEdit(true);
    setQuery(newQuery);
    setErrorMessage("");
  }

  const checkedQuery = data?.getQueryForWorkbook;
  const validQuery = checkedQuery?.validQuery && !queryEdit;
  const handleDeleteWorkbook = () => {
    queryId
      ? navigate(databaseQuery(databaseId, queryId))
      : navigate(databaseWorkbooks(databaseId));
  };
  const workbookTitle = (
    <WorkbookCreationHeader
      name={name}
      description={description}
      setName={setName}
      setDescription={setDescription}
      handleDeleteWorkbook={handleDeleteWorkbook}
    />
  );

  const editButton = (
    <button onClick={() => setQueryEdit(true)}>
      <FontAwesomeIcon icon={faEdit} />
    </button>
  );

  return (
    <PageContent
      windowTitle={`EXPLAIN Workbook: ${name}`}
      title={workbookTitle}
      pageCategory="query-tuning"
      pageName="workbooks"
      layout={validQuery ? "sidebar" : "default"}
    >
      <Panel
        title={<CreateSteps step="step1" />}
        secondaryTitle={validQuery && editButton}
        className="mt-1 flex-grow"
      >
        <PanelSection className="flex-grow flex flex-col items-stretch gap-2">
          {validQuery && (
            <Callout>
              In order to process this query, we normalized it and turned
              positional parameters (<code>$1</code>) into named parameters (
              <code>$param</code>). Because we do not know if a parameter
              contains the same content, we suffix the named parameters with
              consecutive numbers—for example, <code>param</code>,{" "}
              <code>param_2</code>, and <code>param_3</code>.
            </Callout>
          )}
          <div className="flex-grow">
            {validQuery ? (
              <QueryTextArea
                className="h-full"
                queryText={checkedQuery.normalizedQuery.replace(
                  /\$[\d]+/g,
                  (match) => {
                    const matchedParam = checkedQuery.parameters.find(
                      (param) => `$${param.ref}` === match,
                    );
                    return matchedParam ? `$${matchedParam.name}` : match;
                  },
                )}
              />
            ) : (
              <textarea
                className="h-full resize-none rounded border border-[#E8E8EE] p-2 text-[#606060] w-full font-query text-[13px]"
                placeholder="Paste query text here..."
                value={query}
                onChange={(e) => handleUpdateQuery(e.target.value)}
              ></textarea>
            )}
          </div>
          {errorMessage && (
            <div className="text-[#C22426]">
              <FontAwesomeIcon icon={faExclamationCircle} /> {errorMessage}
            </div>
          )}
          <div>
            {validQuery ? (
              <button className="btn btn-success" onClick={handleCreate}>
                Choose Parameters
              </button>
            ) : (
              <button
                className="btn btn-success"
                onClick={handleCheckQuery}
                disabled={query === ""}
              >
                Verify Query
              </button>
            )}
          </div>
        </PanelSection>
      </Panel>
      <div className="h-10">
        {/* margin for above panel; due to layout we can't specify margin there directly */}
      </div>
      {validQuery && (
        <div className="w-[320px]">
          {/* TODO: handle param name update */}
          <Callout className="mb-4" thin>
            {checkedQuery.parameters.length} parameters detected
          </Callout>
          <div className="leading-7 font-medium my-2">Parameter Names</div>
          <div className="grid gap-2">
            <ul className="list-inside">
              {checkedQuery.parameters.map((val, idx) => {
                return <li key={`${val.name}-${idx}`}>{val.name}</li>;
              })}
            </ul>
          </div>
        </div>
      )}
    </PageContent>
  );
};

export const QueryTextArea = ({
  queryText,
  className,
  showCopyToClipboard,
}: {
  queryText: string;
  className?: string;
  showCopyToClipboard?: boolean;
}) => {
  return (
    <div
      className={classNames(
        "relative min-h-[128px] rounded border border-[#E8E8EE] p-2 text-[#606060]",
        className,
      )}
    >
      {showCopyToClipboard && (
        <CopyToClipboard
          className="float-right text-[12px] text-[#606060] ml-1"
          label="copy"
          content={queryText}
        />
      )}
      <SQL
        className="absolute top-2 bottom-2 left-2 right-2 overflow-y-auto"
        sql={queryText}
      />
    </div>
  );
};

export default ReviewQuery;
