import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import moment from "moment-timezone";

import Loading from "components/Loading";
import PageContent from "components/PageContent";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import PanelTable from "components/PanelTable";

import {
  SnapshotDetails as SnapshotDetailsType,
  SnapshotDetailsVariables,
} from "./types/SnapshotDetails";
import QUERY from "./Query.graphql";

const SnapshotDetails: React.FunctionComponent = () => {
  const params = useParams();
  const collectedAt = Number(params.collectedAt);
  const { data, loading, error } = useQuery<
    SnapshotDetailsType,
    SnapshotDetailsVariables
  >(QUERY, {
    variables: {
      serverId: params.serverId,
      collectedAt,
      kind: "full",
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }
  const { submitter, hasError, errorMessage, systemJson } =
    data.getSnapshotDetails;
  return (
    <PageContent title="Snapshot" pageCategory="snapshots" pageName="show">
      <Panel title="Overview">
        <PanelTable horizontal={true}>
          <tbody>
            <tr>
              <th>Collected At</th>
              <td>{moment.unix(collectedAt).format("MMM DD hh:mm:ssa zz")}</td>
              <th>Submitter</th>
              <td>{submitter}</td>
            </tr>
          </tbody>
        </PanelTable>
      </Panel>
      {hasError && errorMessage && (
        <Panel title="Collector Error">
          <PanelSection>
            <p>
              This snapshot could not be collected on your system due to an
              error:
            </p>
            <pre>{errorMessage}</pre>
          </PanelSection>
          <PanelSection>
            <p>
              <strong>Hint:</strong> You can use the --test option of the
              collector to test your configuration.
            </p>
          </PanelSection>
        </Panel>
      )}
      {hasError && !errorMessage && (
        <Panel title="Internal Error">
          <PanelSection>
            <p>
              This snapshot could not be processed due to an internal error.
            </p>
            <p>Please contact support for assistance.</p>
          </PanelSection>
        </Panel>
      )}
      <Panel title="System">
        <PanelSection>
          <pre>{systemJson}</pre>
        </PanelSection>
      </Panel>
    </PageContent>
  );
};

export default SnapshotDetails;
