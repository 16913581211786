import React, { useCallback, useState } from "react";

import moment from "moment-timezone";

import { formatMs, formatTimestampShort } from "utils/format";

import {
  ExplainComparison as ExplainComparisonType,
  ExplainComparisonVariables,
} from "./types/ExplainComparison";

import { AnnotatedPlan } from "types/explain";
import ExplainFingerprint from "components/ExplainFingerprint";

import QUERY from "./Query.graphql";

import { useQuery } from "@apollo/client";
import Loading from "components/Loading";
import { ExplainPlanType } from "components/Explain/util";
import {
  ExplainCostMetric,
  useCostMetric,
  useSetCostMetric,
  WithExplainCostMetric,
} from "components/WithExplainCostMetric";
import ExplainDiff from "components/ExplainDiff";
import {
  ComparablePlanType,
  useSetCurrentComparePlan,
  useSetCurrentPlan,
} from "components/WithExplainPlan";
import { useRouteSearchState } from "utils/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/pro-solid-svg-icons";
import Grid, { MsCell } from "components/Grid";
import ModalContainer from "components/ModalContainer";
import { mru } from "utils/array";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import Button from "components/Button";

const ExplainComparison: React.FunctionComponent<{
  databaseId: string;
  explain: ExplainPlanType;
  plan: AnnotatedPlan;
  blockSize: number;
}> = ({ databaseId, blockSize, explain }) => {
  // TODO: Rework the data fetching to only load the annotated JSON for the plans being compared
  return (
    <ExplainComparisonQueryPage
      databaseId={databaseId}
      queryId={explain.query.id}
      blockSize={blockSize}
    />
  );
};

const ExplainComparisonQueryPage: React.FunctionComponent<{
  databaseId: string;
  blockSize: number;
  queryId: string;
}> = ({ databaseId, blockSize, queryId }) => {
  const { error, loading, data } = useQuery<
    ExplainComparisonType,
    ExplainComparisonVariables
  >(QUERY, {
    variables: {
      databaseId,
      queryId,
    },
  });
  if (loading || error) {
    /* TODO: This now doesn't have the panel around it, what's our typical styling here? */
    return <Loading error={!!error} />;
  }
  const comparablePlans = data.getQueryExplains.map((e) => ({
    id: e.id,
    seenAt: e.seenAt,
    fingerprint: e.fingerprint,
    label: formatTimestampShort(moment.unix(e.seenAt)),
    runtime: e.querySample.runtimeMs,
    ioMs: e.totalBlkReadTime,
    ioBytes: e.totalSharedBlksRead * blockSize,
    totCost: e.totalCost,
    plan: JSON.parse(e.annotatedJson),
  }));
  return <ExplainComparisonContent comparablePlans={comparablePlans} />;
};

export const ExplainComparisonContent: React.FunctionComponent<{
  comparablePlans: ComparablePlanType[];
}> = ({ comparablePlans }) => {
  const [planA, planB] = useComparisonPlans(comparablePlans);
  // If we don't have both plans selected, open the modal immediately
  const [open, setOpen] = useState(!planA || !planB);

  function toggleOpen() {
    setOpen((open) => !open);
  }
  const diffPlanA = planA && { label: planA.label, plan: planA.plan.plan };
  const diffPlanB = planB && { label: planB.label, plan: planB.plan.plan };

  return (
    <div>
      <WithExplainCostMetric>
        <Panel
          className={
            "mt-[1px]" /* TODO: On Firefox, the top border of the panel is cut off without an extra margin
        here because we're using box-shadow to draw the border; this should not be necessary. */
          }
          title="Plan Comparison"
          secondaryTitle={
            <Button
              className="text-[#337ab7] hover:text-[#23527c]"
              bare
              onClick={toggleOpen}
            >
              Select plans
            </Button>
          }
        >
          <PanelSection>
            {(!planA || !planB) && (
              <div>
                <FontAwesomeIcon
                  icon={faEdit}
                  className="text-[#31708f] mr-1"
                />
                <Button
                  className="text-[#337ab7] hover:text-[#23527c]"
                  bare
                  onClick={toggleOpen}
                >
                  Select plans
                </Button>{" "}
                to continue
              </div>
            )}
            {open && (
              <SelectPlansToCompareModal
                toggleOpen={toggleOpen}
                comparablePlans={comparablePlans}
              />
            )}
            {planA && planB && (
              <>
                <ExplainSelectCostMetric />
                <ExplainDiff planA={diffPlanA} planB={diffPlanB} />
              </>
            )}
          </PanelSection>
        </Panel>
      </WithExplainCostMetric>
    </div>
  );
};

export function useComparisonPlans(comparablePlans: ComparablePlanType[]) {
  const [plans] = useRouteSearchState({
    key: "planCompare",
    decode: decodePlanComparison,
    encode: encodePlanComparison,
  });

  const [planAId, planBId] = plans ?? [null, null];

  const planA = comparablePlans.find((p) => p.id === planAId);
  const planB = comparablePlans.find((p) => p.id === planBId);

  return [planA, planB];
}

function useSetComparisonPlans() {
  const [, setPlans] = useRouteSearchState({
    key: "planCompare",
    decode: decodePlanComparison,
    encode: encodePlanComparison,
  });

  const setPlanA = useSetCurrentPlan();
  const setPlanB = useSetCurrentComparePlan();

  const setComparisonPlans = useCallback(
    (planA: ComparablePlanType, planB: ComparablePlanType) => {
      setPlanA(planA);
      setPlanB(planB);
      setPlans([planA.id, planB.id]);
    },
    [setPlanA, setPlanB, setPlans],
  );

  return setComparisonPlans;
}

function encodePlanComparison(plans: [planA: string, planB: string]) {
  return plans.join("-vs-");
}

function decodePlanComparison(comparison: string) {
  return comparison.split("-vs-") as [planA: string, planB: string];
}

function SelectPlansToCompareModal({
  comparablePlans,
  toggleOpen,
}: {
  comparablePlans: ComparablePlanType[];
  toggleOpen: () => void;
}) {
  const [planA, planB] = useComparisonPlans(comparablePlans);
  const setComparisonPlans = useSetComparisonPlans();

  const initialCandidates = [] as string[];
  if (planA) {
    initialCandidates.push(planA.id);
  }
  if (planB) {
    initialCandidates.push(planB.id);
  }
  const [compareCandidates, setCompareCandidates] =
    useState<string[]>(initialCandidates);

  function addCompareCanditate(candidateId: string) {
    const newCandidates = mru(candidateId, compareCandidates, 2);
    setCompareCandidates(newCandidates);
  }

  function clearCompareCanditate(candidateId: string) {
    const newCandidates = compareCandidates.filter((id) => id !== candidateId);
    setCompareCandidates(newCandidates);
  }

  let candidateA: ComparablePlanType, candidateB: ComparablePlanType;
  if (compareCandidates.length > 0) {
    candidateA = comparablePlans.find((p) => p.id === compareCandidates[0]);
  }
  if (compareCandidates.length > 1) {
    // If the user selects two plans, we want the oldest selection to be Plan A
    // and the most recent to be Plan B (we expect this is more intuitive).
    // Since the result of `mru` is reversed (it's sorted by recency), we need
    // to switch these:
    candidateB = candidateA;
    candidateA = comparablePlans.find((p) => p.id === compareCandidates[1]);
  }

  return (
    <ModalContainer
      title="Select plans to compare"
      layout="centered"
      onClose={toggleOpen}
    >
      <Grid
        pageSize={10}
        data={comparablePlans}
        columns={[
          {
            field: "id",
            header: "",
            disableSort: true,
            width: "34px",
            renderer: ({ fieldData }) => {
              function handleToggleChecked(
                e: React.ChangeEvent<HTMLInputElement>,
              ) {
                if (e.currentTarget.checked) {
                  addCompareCanditate(fieldData);
                } else {
                  clearCompareCanditate(fieldData);
                }
              }
              const checked = compareCandidates.includes(fieldData);
              return (
                <input
                  type="checkbox"
                  className="cursor-pointer !mt-0.5"
                  checked={checked}
                  onChange={handleToggleChecked}
                />
              );
            },
          },
          {
            field: "fingerprint",
            width: "120px",
            renderer: ({ fieldData }) => {
              return <ExplainFingerprint fingerprint={fieldData} />;
            },
          },
          {
            width: "1fr",
            field: "label",
          },
          {
            field: "runtime",
            style: "number",
            nullValue: "n/a",
            renderer: MsCell,
            width: "minmax(10%,180px)",
          },
          {
            field: "ioMs",
            header: "I/O Read Time",
            style: "number",
            nullValue: "n/a",
            renderer: MsCell,
            width: "minmax(10%,180px)",
          },
        ]}
      />
      <div className="mt-4">
        <PlanSummaryTable planA={candidateA} planB={candidateB} />
      </div>
      <button
        disabled={compareCandidates.length < 2}
        className="mt-4 btn btn-success"
        onClick={() => {
          if (compareCandidates.length < 2) {
            return;
          }
          setComparisonPlans(candidateA, candidateB);
          toggleOpen();
        }}
      >
        Compare Plans
      </button>
    </ModalContainer>
  );
}

function PlanSummaryTable({
  planA,
  planB,
}: {
  planA: ComparablePlanType;
  planB: ComparablePlanType;
}) {
  return (
    <table className="w-full border-spacing-1 border-separate table-fixed">
      <thead>
        <tr>
          <th className="w-16"></th>
          <th className="w-32">Fingerprint</th>
          <th className="w-full">Description</th>
          <th className="w-32 text-right">Runtime</th>
          <th className="w-32 text-right">I/O Read Time</th>
        </tr>
      </thead>
      <tbody>
        <PlanSummaryRow label="Plan A" plan={planA} />
        <PlanSummaryRow label="Plan B" plan={planB} />
      </tbody>
    </table>
  );
}

function PlanSummaryRow({
  label,
  plan,
}: {
  label: string;
  plan: ComparablePlanType;
}) {
  return (
    <tr>
      <th className="whitespace-nowrap h-6" scope="row">
        {label}
      </th>
      <td>{plan ? <ExplainFingerprint explain={plan} /> : "n/a"}</td>
      <td>{plan?.label ?? "n/a"}</td>
      <td className="text-right">
        {plan?.runtime != null ? formatMs(plan.runtime) : "n/a"}
      </td>
      <td className="text-right">
        {plan?.ioMs != null ? formatMs(plan.ioMs) : "n/a"}
      </td>
    </tr>
  );
}

function ExplainSelectCostMetric() {
  const costMetric = useCostMetric();
  const setCostMetric = useSetCostMetric();
  function handleMetricChange(evt: React.ChangeEvent<HTMLInputElement>) {
    setCostMetric(evt.currentTarget.value as ExplainCostMetric);
  }

  return (
    <div className="mb-2 flex gap-2 items-baseline max-w-full">
      <strong className="text-sm">Compare:</strong>
      <label className="font-semibold">
        <input
          className="!mr-1"
          type="radio"
          value="Est. Cost"
          checked={costMetric == "Est. Cost"}
          onChange={handleMetricChange}
        />
        Est. Total Cost (Self)
      </label>
      <label className="font-semibold">
        <input
          className="!mr-1"
          type="radio"
          value="Runtime"
          checked={costMetric == "Runtime"}
          onChange={handleMetricChange}
        />
        Runtime (Self)
      </label>
      <label className="font-semibold">
        <input
          className="!mr-1"
          type="radio"
          value="I/O Time"
          checked={costMetric == "I/O Time"}
          onChange={handleMetricChange}
        />
        I/O Read Time (Self)
      </label>
      <label className="font-semibold">
        <input
          className="!mr-1"
          type="radio"
          value="Rows"
          checked={costMetric == "Rows"}
          onChange={handleMetricChange}
        />
        Rows
      </label>
    </div>
  );
}

export default ExplainComparison;
